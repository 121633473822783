import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">How we operate</h1>
            <p className="font13" style={{ textAlign: "justify" }}>
            Operating the LINSON firm in the healthcare sector, Arunachala Enterprises focuses on the design, production, distribution, and frequently the maintenance of surgical instruments, equipment, and related items. In order to improve patient outcomes during surgical procedures, the company combines scientific innovation, regulatory compliance, careful production, efficient sales and marketing strategies, and committed customer service.<br />

            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="Business needs"
                title=""
                text=" Continuous innovation is essential to develop cutting-edge surgical instruments that improve patient outcomes, enhance surgical precision, and reduce recovery times. Allocate resources to R&D to stay ahead of competitors, address emerging clinical needs, and incorporate new materials and technologies into product development."
                offers={[
                  ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="Quality Build"
                title=""
                text=" Meeting stringent regulatory standards is non-negotiable for market entry and maintaining customer trust. Conduct thorough testing and validation of products, and ensure adherence to regulatory requirements throughout the product lifecycle."
                offers={[
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="Full transparency"
                title=""
                text="Embracing transparency enhances reputation, fosters trust, and ensures accountability in the surgical lab industry. By prioritizing transparency in all aspects of operations and interactions, surgical can strengthen relationships with stakeholders and contribute to improved patient care outcomes"
                offers={[
                 ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  overflow-x: hidden;
  overflow-x: clip !important;
  padding: 50px 0;
  overflow-x: hidden;
  overflow-x: clip !important;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    overflow-x: hidden;
    overflow-x: clip !important;
    max-width: 370px;
    margin: 0 auto
    overflow-x: hidden;
    overflow-x: clip !important;
  }
`;




