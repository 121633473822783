import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Infrastructure</h1>
            <p className="font13" >
        The infrastructure for surgical facilities, often referred to as surgical suites or operating rooms (ORs), is critical to ensure optimal conditions for performing surgeries safely and effectively.
              <br />
              The infrastructure of a surgical is crucial for ensuring patient safety, supporting medical staff, and maintaining sterile conditions necessary for successful surgeries.
            </p>
          </HeaderInfo>
          <div className="row textCenter" >
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox 
                title="
                CLEAN ROOM INTERNATIONAL STANDARD"
                text="We thoroughly follow all safety procedures because the hygiene of the manufactured product is our first concern. Microorganism and air particle counts are tested often to meet international requirements. To ensure efficacy, our staff follows strict clean room protocols."
                tag=""
                author="Quality remembered long after price is forgotten"
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="
              PRODUCTION PROCEDURES AND WAREHOUSES"
                text="The production and storage of disposable goods are handled in the clean warehouses using the most recent material management systems. Before being accepted, the raw materials are first examined and diagnosed for quality assurance. The final goods are kept in a climate-controlled warehouse where the humidity and temperature are monitored continuously. The raw materials are moved from storage to the sterilization room via a centralized material handling system, where the subsequent process takes place. By using this procedure, hospital disposable items are kept below the required international criteria for germs and air particle counts."
                tag=""
                author=" When Quality and Efficiency in place there is no room for fail"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="PERSONAL ANALYZATION"
                text="Next, a fully skilled and trained inspection crew goes through the constructed disposable items to ensure 100% quality control. To guarantee the precision and excellence of the product, a thorough function inspection of the sterilized goods is next carried out."
                tag=""
                author="More we explore, More we grow"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="SANITIZATION"
                text="Units are used to store the disposables, which are then sanitized and put together in boxes. Every sterilization procedure is observed using biological testing protocols and chemical markers. Until the sterilised packs pass authorized lab testing, they are all maintained in quarantine. Following a successful sterilizing process, the products are transferred to a completed goods warehouse in order to be distributed."
                tag=""
                author="Senior Geeks"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="
                EXCELLENT COLLABORATION"
                text="To ensure that the goods are of the highest caliber, our quality controllers inspect and work diligently. We supply top-notch products that are accessible to every consumer for use by utilizing the greatest quality enhancement techniques along with ISO and CE standards at every turn. We guarantee the manufacture of exceptionally high-quality hospital disposables. Repeat business has led to robust and quickly rising sales, which speaks highly of our abilities."
                
                tag=""
                author="A Common Agenda Always Wins"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="RELIABLE AND ACCREDITED 
                
                "
                text="Surgicals has been granted certification by CE and ISO.Lifecare Surgicals strives to offer High Quality Medical Disposable items in light of the swift evolution and progress of technology. Modern technology and a team of highly talented, committed, and well-trained employees work to guarantee client satisfaction."
                tag=""
                author="A strong foundation uplifts it's strenght"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What Clients State?</h1>
            <p className="font13" style={{ textAlign: "justify" }}>
            A key to your success is getting feedback frequently and early on. To improve continuously, you must arrange your work so that you can receive feedback, be transparent so that others can see what needs to be improved, and incorporate that input into your work.
              <br />
              I believe the features we're implementing for our upcoming version will be quite helpful to you in this regard. And this is only the start. We plan to keep investing since we have a ton of more ideas about how to make feedback cycles richer and more comprehens


            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  overflow-x: hidden;
  overflow-x: clip !important;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;