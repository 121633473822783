import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import styled from "styled-components";
// Components
// import FullButton from "../Buttons/FullButton";
import ProjectBox from "../Elements/ProjectBox";
// Assets
import AddImage2 from "../../assets/img/add/add2.png";
import ProjectImg4 from "../../assets/img/projects/regular.png";
import ProjectImg5 from "../../assets/img/projects/vented.png";
import ProjectImg6 from "../../assets/img/projects/nonvented.png";
import ProjectImg1 from "../../assets/img/projects/glv.png";
import ProjectImg2 from "../../assets/img/projects/needles.png";
import ProjectImg3 from "../../assets/img/projects/syringe.png";
import ProjectImg7 from "../../assets/img/projects/microdripper.png";
import ProjectImg8 from "../../assets/img/projects/autosyringe.png";
import ProjectImg9 from "../../assets/img/projects/cannula.png";


export default function Projects() {
  useEffect(()=>{
    Aos.init({duration:1000});
  });
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div data-aos="slide-up" className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Products</h1>
            <p className="font13" style={{ textAlign: "justify" }}>
            The happiness of our customers is our main priority. Our goal is to provide each and every consumer with our cutting-edge line of disposable goods. Our goal is to improve the caliber of our offerings beyond what has previously been established.
            In our view, doing business involves more than just exchanging money for products or services. We respect open communication, teamwork, transparency, and high-caliber outputs because of this. We like to operate within a close feedback loop.  </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Latex Examination Gloves"
                text="Color : White/Blue--
                Size : Small, Medium, Large, Ex-Large -- 
                Packing : 100 Pcs. Per Box --
                MOQ : As per Requirement --
                Price : Depends on Order quantity,
                Additionally offered in a single sterile pack"
                action={() => alert("clicked")}
              >
                
                </ProjectBox>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Disposable Hypodermic Needles"
                text="Single-use--
                Sterilized hypodermic needles. Syringes on infusion settings and clinical medication solution injection--
                Specifications: 16G/17G/18G/19G/20G/21G/22G/23G/24G/25G/26G/27G/30G--
                Sterilization : E.O"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Disposable Hypodermic Syringe"
                text="EtO Sterile--
                Non-Pyrogenic--
                Non-Toxic--
                Size : 1ml/2ml/3ml/5ml/10ml/20ml/30ml/40ml/50ml--
                MOQ : As per Requirment--
                Price : Depends on Order quantity"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="I.V. Set Regular"
                text="IV  Administration Set--
                Non-Vented--
                Spike 20 drops-ml with Guard/Drip Chamber 45mm--
                Fluid Filter/Tube Latex/Flow Regulator Luer Tip  male Adaptor/Needles 21gx1.25 with Protector 2.6mm*3.2mm PVC Tubing-Length 150cm/ EO Sterile."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="I.V. Set Vented"
                text="IV  Administration Set--
                Non-Vented--
                Spike 20 drops-ml with Guard/Drip Chamber 45mm--
                Fluid Filter/Tube Latex/Flow Regulator Luer Tip  male Adaptor/Needles 21gx1.25 with Protector 2.6mm*3.2mm PVC Tubing-Length 150cm/ EO Sterile."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="I.V. Set Non Vented"
                text="IV  Administration Set Premium--
                Non-Vented--
                Spike 20 drops-ml with Guard/Drip Chamber 45mm--
                Fluid Filter/Bulb Latex/Flow Regulator Luer Tip  male Adaptor/Needles 21gx1.25 with Protector 2.6mm*3.2mm PVC Tubing-Length 150cm/ EO Sterile.

"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg7}
                title="I.V. Set with Micro dripper"
                text="IV  Administration Set--
                Non-Vented/Vented--
                Micro Dripper--
                Spike 20 drops-ml with Guard/Drip Chamber 45mm--
                Fluid Filter/Tube Latex/Flow Regulator Luer Tip  male Adaptor/Needles 21gx1.25 with Protector 2.6mm*3.2mm PVC Tubing-Length 150cm/ EO Sterile."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg8}
                title="Disposable Syringe Auto"
                text="EtO Sterile--
                Non-Pyrogenic--
                Non-Toxic--
                Size : 0.1ml/0.5ml/1ml/2ml/3ml/5ml--
                MOQ : As per Requirment--
                Price : Depends on Order quantity"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg9}
                title="I.V. Canuula Disposable"
                text="IV Canuula with or without Wings and with or without Port--
                Size : 14G to 26G--
                MOQ : As per Requirment--
                Price : Depends on Order quantity"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/* <FullButton title="Load More" action={() => alert("clicked")} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">LINSON</h4>
              <h2 className="font40 extraBold">Simplified and Transparent Surgicals</h2>
              <p className="font12" style={{ textAlign: "justify" }} >
              To guarantee that procedures are performed safely, effectively, and with the greatest possible results for patients, every member of the surgical team is essential. The efficient operation of a surgical team depends on cooperation, communication, and protocol observance.
              </p>
              {/* <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Contact Us" 
                  // action={() => alert("clicked")}
                   />
                </div>
                
              </ButtonsRow> */}
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  overflow-x: hidden;
  overflow-x: clip !important;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
// const ButtonsRow = styled.div`
//   @media (max-width: 860px) {
//     justify-content: space-between;
//   }
// `;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-x: clip !important;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-x: clip !important;
  padding: 0 15%;
  img {
    width: 100%;
    overflow-x: hidden;
    overflow-x: clip !important;
    height: auto;
    overflow-x: hidden;
    overflow-x: clip !important;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
