import React from "react";
import styled from "styled-components";

export default function ProjectBox({ img, title, text, action}) {
  const textWithBreaks = text.split('--').map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
));
  return (
    <Wrapper>
      <ImgBtn className="aniamte pointer" onClick={action ? () => action() : null}>
        <img className="radius8" src={img} alt="project"></img>
      </ImgBtn>
      <h3 className="font20 extraBold">{title}</h3>
      
      <p className="font13" style={{ textAlign: "justify" }}>{textWithBreaks}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-x: clip !important;
  margin-top: 30px;
  img {
    width: 100%;
    overflow-x: hidden;
    overflow-x: clip !important;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;