import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="121" height="48">
      <path fill="#712B5D" d="M0 0h121v48H0V0z"></path>
      <path
        fill="#F6E5E0"
        d="M85.504 10.902l3.058.036 3.067.027L94 11l-.48 1.73c-.514 2.242-.722 4.303-.836 6.598l-.127 2.422-.12 2.5-.13 2.547A2237.62 2237.62 0 0092 33l4-1-1 3h-5l-1-7c-2.452.106-2.452.106-5 1-1.526 2.846-1.526 2.846-2 6h-6l1.805-2.05c2.798-3.76 4.35-7.712 6.082-12.042C85.019 18.136 85.857 16.142 88 14c-3.716-.2-4.772-.152-8 2 2.408-4.977 2.408-4.977 5.504-5.098z"
      ></path>
      <path
        fill="#E2C9CC"
        d="M33 5l4 2c-2.256 5.44-5.43 9.35-10 13a168.693 168.693 0 01-6 2c-2.477 1.85-4.75 3.883-7 6 1.273-2.788 2.242-4.236 4.5-6.438 2.588-2.304 2.588-2.304 3.375-4.937C24.033 11.59 28.513 7.991 33 5z"
      ></path>
      <path
        fill="#E5CDCF"
        d="M107.125 11.938l3.883.027L114 12l-1 2h-8l-1 8h7l-1 2h-7l.063 3.375C103 31 103 31 102 33l10-1c-3 3-3 3-5.7 3.293l-2.987-.105-3.012-.083L98 35l.621-2.309c1.632-6.253 2.842-12.25 3.379-18.691l-4 1v-2c3.003-1.502 5.79-1.093 9.125-1.063z"
      ></path>
      <path
        fill="#EBD6D5"
        d="M73.75 11.25L77 12v4h-2l-1-2h-4c-.421 2.246-.421 2.246 0 5l2.5 2.938C75 25 75 25 75.625 28.063 74.775 32.06 73.232 33.557 70 36c-3.052.581-4.294.47-6.875-1.25C62 33 62 33 62.312 30.812L63 29h2l2 4h4c.541-4.532.541-4.532-1.113-8.625l-1.387-1.5C66 20 66 20 65.437 17.375c1.181-4.986 3.247-6.463 8.313-6.125z"
      ></path>
      <path
        fill="#E9D2D3"
        d="M62 9v2l-7 2-4 19h10l-2 3c-3.258.293-3.258.293-7.125.188l-3.883-.083L45 35v-2l3-2c.733-3.541 1.227-7.027 1.64-10.615C50.7 12.949 53.75 8.04 62 9z"
      ></path>
      <path
        fill="#D6B7C0"
        d="M88 14h3c-.14 1.813-.287 3.625-.438 5.438-.08 1.009-.162 2.018-.246 3.058C90 25 90 25 89 26c-1.666.04-3.334.043-5 0-1-3-1-3-.258-5.008l1.133-2.117 1.117-2.133C87 15 87 15 88 14z"
      ></path>
      <path
        fill="#D7BAC1"
        d="M12 31c3.938 3.323 3.938 3.323 4.25 6.75L16 40c-2.375.625-2.375.625-5 1l-2-2c.353-3.442 1.046-5.069 3-8z"
      ></path>
      <path
        fill="#D6B8C0"
        d="M62 9v2l-7 2-2 9h-3c.24-4.734.363-7.126 3.188-11C56.45 8.68 58.084 8.545 62 9z"
      ></path>
      <path
        fill="#CFAFBA"
        d="M82 25l1 2h7v6h-1v-5c-2.452.106-2.452.106-5 1-1.526 2.846-1.526 2.846-2 6h-6l1.875-2.188C79.837 30.216 80.953 28.053 82 25z"
      ></path>
      <path
        fill="#D2B2BC"
        d="M72 28l2 4c-3.27 3.875-3.27 3.875-6.563 4.438C65 36 65 36 63.126 34.75 62 33 62 33 62.312 30.812L63 29h2l2 4h4l1-5z"
      ></path>
      <path
        fill="#D7B9C1"
        d="M85.504 10.902l3.058.036 3.067.027L94 11l-1 4c-.687-.095-1.374-.19-2.082-.29-.901-.11-1.802-.22-2.73-.335l-2.708-.352c-2.737-.258-2.737-.258-5.48 1.977 2.408-4.977 2.408-4.977 5.504-5.098z"
      ></path>
      <path fill="#7B3966" d="M89 15h1v10h-4c.75-6.625.75-6.625 3-10z"></path>
      <path
        fill="#D5B7BF"
        d="M77 12v4h-2l-1-2h-4l-2 4c-.688-1.813-.688-1.813-1-4 2.36-4.279 5.805-2.87 10-2z"
      ></path>
      <path
        fill="#E4CCCF"
        d="M66 15h2c2.042 2.905 3 4.401 3 8h-3c-2.152-3.228-2.2-4.284-2-8z"
      ></path>
      <path fill="#E6CED0" d="M91 22h1v11l4-1-1 3h-5l1-13z"></path>
      <path fill="#ECD7D6" d="M12 31l3 2v4h-5c.875-4.875.875-4.875 2-6z"></path>
    </svg>
  );
}

export default Icon;
