import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import styled from "styled-components";
// Components
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import Dots from "../../assets/svg/Dots";
import QuotesIcon from "../../assets/svg/Quotes";

export default function Header() {
  useEffect(()=>{
    Aos.init({duration:2000});
  });
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div data-aos="fade-in">
          <h1 className="extraBold font60">A Hub of Sterilized Disposable products</h1>
          <HeaderP className="font13 semiBold" style={{textAlign: "justify"}} > 
          The company that produces ETO-sterilized disposables, including bandage cloths, surgical gloves, IV sets, blood transfusion sets, hypodermic needles, and auto-disposable syringes, is Arunachala Enterprises through Linson Surgicals. The Trademark has been issued to Linson Surgicals. Aiming to provide High Quality Medical Disposables, Linson Surgicals recognizes the rapid advancement and change in technology. To ensure customer pleasure, a group of exceptionally gifted, devoted, and skilled workers collaborate with state-of-the-art technology.
          </HeaderP>
          {/* <BtnWrapper>
            <FullButton title="Get Started" />
          </BtnWrapper> */}
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor" style={{ textAlign: "justify" }}>
                <em>Quality is more important than quantity. One home run is much better than two doubles.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>- Steve Jobs</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  overflow-x: hidden;
  overflow-x: clip !important;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    overflow-x: hidden;
    overflow-x: clip !important;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    overflow-x: hidden;
    overflow-x: clip !important;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
    overflow-x: hidden;
    overflow-x: clip !important;
  }
`;

const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    overflow-x: hidden;
    overflow-x: clip !important;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


