import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
function CustomChatbot(props) {
  const config = {
    width: "300px",
    height: "400px",
    floating: true
  };
  const theme = {
    background: "white",
    fontFamily: "Arial, Helvetica, sans-serif",
    headerBgColor: "#00B2B2",
    headerFontColor: "#fff",
    headerFontSize: "25px",
    botBubbleColor: "#00B2B2",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4c4c4c"
   };
  const steps = [
    {
       id: "Greet",
       message: "Hello, Welcome to LINSON",
       trigger: "Ask Name"
    },
    {
       id: "Ask Name",
       message: "May I have your name?",
       trigger: "Waiting user input for name"
    },
    {
       id: "Waiting user input for name",
       user: true,
       trigger: "Asking details"
    },
    {
       id: "Asking details",
       message: "Hi {previousValue}, Enter your contact number",
       trigger: "Waiting for contact number"
    },
    {
      id: "Waiting for contact number",
      user: true,
      trigger: "contact number"
   },
    {
      id: "contact number",
      message: "Share us your email address",
      trigger: "Waiting for email"
   },
   {
    id: "Waiting for email",
    user: true,
    trigger: "description"
   },
   {
    id: "description",
    message: "Please provide us a brief description of your inquiry.",
    trigger: "Waiting for description"
   }, 
   {
    id: "Waiting for description",
    user: true,
    trigger: "Thanks note"
   },
   {
    id: "Thanks note",
    message: "Thank you. Have a great day!",
    trigger: "Done"
   },
    {
        id: "Done",
        message: "We will contact you shortly.",
        end: true
    }
];

return (
   <ThemeProvider theme={theme}>
      <ChatBot steps={steps} {...config} />
   </ThemeProvider>
  );
 }
 export default CustomChatbot;